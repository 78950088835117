body {
  background-color: #f6f6f7;
}
.Polaris-DataTable__Cell--firstColumn {
  /*width: 125px;*/
}
table tfoot{display:table-row-group;}

@media print {
  .hideprint {
    display: none;
  }
}

.hide-orders{
 th:nth-child(4), td:nth-child(4) {
    display: none;
  }
  th:nth-child(6), td:nth-child(6) {
    display: none;
  }
}

.hide-amounts {
  th:nth-child(3), td:nth-child(3) {
    display: none;
  }
  th:nth-child(5), td:nth-child(5) {
    display: none;
  }
}

.hide-total {
  th:nth-child(2), td:nth-child(2) {
    display: none;
  }
}

.table-thuislevering {
  th:nth-child(2), td:nth-child(2) {
    display: none;
  }
  th:nth-child(5), td:nth-child(5) {
    display: none;
  }
  th:nth-child(6), td:nth-child(6) {
    display: none;
  }
}

.table-ophaling {
  th:nth-child(2), td:nth-child(2) {
    display: none;
  }
  th:nth-child(3), td:nth-child(3) {
    display: none;
  }
  th:nth-child(4), td:nth-child(4) {
    display: none;
  }
}